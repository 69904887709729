import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import ProductShortcut from "../ProductShortcut"

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    /* justify-content: start; */
    align-items: start;
    gap: 5rem;

    width: 100%;
    max-width: 100rem;
    margin: 0 auto;

    &.reversed {
        flex-direction: row-reverse;
    }

    ${props => props.theme.below.desktop`
        flex-direction: column;
        max-width: none;

        padding: 0 3rem;

        gap: 3rem;

        &.reversed {
            flex-direction: column;
        }
    `}
`
const ImgCol = styled.div`
    position: relative;

    width: 50%;

    ${props => props.theme.below.desktop`
        width: 100%;
    `}
`
const StyledImage = styled(Img)`
    width: 100%;
`

const Text = styled.div`
    width: 50%;

    strong,
    b {
        font-weight: 600;
        color: #fff;
    }

    ul,
    ol,
    ol li,
    ul li {
        list-style: inherit;
        padding-left: 2rem;
    }

    ol {
        list-style: decimal;
    }

    ul li,
    ol li {
        font-family: NeueMontrealRegular;
        font-size: 1.4rem;
        line-height: 2.5rem;
        letter-spacing: 0.12rem;
        color: #949494;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: ${props => props.theme.fontFamily.circularMedium};
        font-size: 1.75rem;
        line-height: 2.25rem;
        margin-bottom: 1rem;
    }

    ${props => props.theme.below.desktop`
        width: 100%;
    `}
`

const StyledProductShortcut = styled(ProductShortcut)`
    display: flex;

    position: absolute;

    bottom: 3rem;
    left: 3rem;
    right: initial;
`

const ImageWithText = data => {
    return (
        <FlexRow
            className={
                data.data.primary.text_w_image_reversed_row
                    ? "reversed"
                    : "normal"
            }
        >
            <Text
                dangerouslySetInnerHTML={{
                    __html: data.data.primary.text_w_image_text.html,
                }}
            />
            <ImgCol>
                <StyledImage
                    fluid={data.data.primary.text_w_image_image.fluid}
                    alt={data.data.primary.text_w_image_image.alt}
                    loading="eager"
                />
                {data.data.primary.product_link.document && (
                    <StyledProductShortcut
                        lang={data.lang}
                        product={data.data.primary.product_link.document}
                        open={false}
                        // noAnimation={true}
                        reversed={true}
                    />
                )}
            </ImgCol>
        </FlexRow>
    )
}

export default ImageWithText
