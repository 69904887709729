import React, { useContext } from "react"
import { scroller } from "react-scroll"
import { graphql } from "gatsby"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"
import { CopyToClipboard } from "react-copy-to-clipboard"

import Seo from "./../components/Layout/Seo"
import Banner from "../components/Banner"
import LinkedImage from "../components/LinkedImage"
import { Related, SwiperCarousel, YoutubeVideo } from "./../components/Shared"
import ShareLinks from "./../components/ShareLinks"

import { getBlogPostLink } from "./../utils/helpers"

import DateSVG from "./../graphics/date.svg"
import LinkSVG from "./../graphics/link.svg"
import AuthorSVG from "./../graphics/author.svg"
import { DictionaryContext } from "../contexts/dictionary"
import ImageWithText from "../components/Blog/ImageWithText"
import CTABanner from "../components/Blog/CTABanner"

const Information = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    width: 100%;

    padding: 6rem 3rem 3rem 3rem;

    ${props => props.theme.above.desktop`
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 3rem;

        max-width: 62.5rem;
    
        margin: 0 auto;
        padding: 6rem 0 0 0;
    `}
`

const Title = styled.h2`
    width: 100%;
    max-width: 70rem;
    margin: 8rem auto 0 auto;
    text-align: center;

    ${props => props.theme.below.desktop`
        display: none;
    `}
`

const InfoItem = styled.p`
    display: flex;
    align-items: center;

    text-transform: capitalize;

    svg {
        margin-right: 1rem;
    }

    color: ${props => props.theme.colors.darkText};
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;

    /* width: 100%;
    max-width: 62.5rem;
    margin: 0 auto; */

    padding: 3rem 0;

    > div {
        margin-bottom: 3rem;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    ${props => props.theme.above.desktop`
        padding: 6rem 0 12rem 0;

        > div {
            margin-bottom: 12rem;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    `}
`

const Content = styled.div`
    /* position: relative;
    
    width: 100vw;
    max-width: 62.5rem;
    margin: 0 auto; */
`

const Text = styled(motion.div)`
    padding: 0 3rem;

    width: 100%;
    max-width: 62.5rem;

    p {
        margin-bottom: 3rem;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .Quote {
        display: block;

        font-family: ${props => props.theme.fontFamily.minionMedium};
        font-size: 2.5rem;
        line-height: 3rem;

        color: ${props => props.theme.colors.white};
    }

    ${props => props.theme.above.desktop`
        p {
            line-height: 3.5rem;
        }

        .Quote {
            font-size: 2.5rem;
            line-height: 4rem;
        }
    `}
`

const StyledLinkedImage = styled(LinkedImage)`
    width: 100%;
    max-width: 100rem;

    margin: 0 auto;
`

const VideoWrapper = styled.div`
    width: 100%;
    max-width: 100rem;

    margin: 0 auto;
`

const transition = {
    duration: 0.55,
    damping: 100,
    stiffness: 100,
    ease: "circOut",
}

const BlogPost = ({
    pageContext: { lang, langSlug },
    data: {
        item: { uid, data },
    },
}) => {
    const [wrapperRef, inView] = useInView({
        triggerOnce: true,
    })

    const animation = inView ? "after" : "before"

    const {
        post_title,
        post_author,
        post_category,
        post_date,
        post_image,
        post_excerpt,
        body,
        related_items,
        seo_title,
        seo_description,
        seo_keywords,
        seo_image,
    } = data

    const dictionary = useContext(DictionaryContext)

    return (
        <>
            <Seo
                title={seo_title}
                description={seo_description.text}
                keywords={seo_keywords}
                image={seo_image}
            />
            <Banner
                lang={langSlug}
                image={post_image}
                title={post_title}
                type={post_category}
                subtitle={post_excerpt}
                overlayAmount={post_image[0].image_overlay_amount}
                onClick={() =>
                    scroller.scrollTo("content", {
                        duration: 1500,
                        delay: 100,
                        smooth: "easeInOutQuint",
                    })
                }
                reversed
            />
            <Information>
                <InfoItem>
                    <DateSVG />
                    <span>{post_date}</span>
                </InfoItem>
                <InfoItem>
                    <AuthorSVG />
                    <span>{post_author}</span>
                </InfoItem>
                <CopyToClipboard
                    text={getBlogPostLink(`blogs-and-inspiration/${uid}`)}
                    style={{ cursor: "pointer" }}
                >
                    <InfoItem>
                        <LinkSVG />
                        <span>Copy link</span>
                    </InfoItem>
                </CopyToClipboard>
            </Information>
            {/* <Content> */}
            {/* </Content> */}
            <Title>{post_title.text}</Title>
            {body && (
                <Wrapper ref={wrapperRef} name="content">
                    <ShareLinks
                        url={getBlogPostLink(`blogs-and-inspiration/${uid}`)}
                        title={post_title}
                        excerpt={post_excerpt.text}
                    />
                    {body.map((item, i) =>
                        (() => {
                            switch (item.__typename) {
                                case "PrismicBlogPostBodyTextWithImage":
                                    return (
                                        <ImageWithText
                                            key={i}
                                            data={item}
                                            lang={langSlug}
                                        />
                                    )
                                case "PrismicBlogPostBodyCtaBanner":
                                    return (
                                        <CTABanner
                                            key={i}
                                            data={item}
                                            lang={langSlug}
                                        />
                                    )
                                case "PrismicBlogPostBodyText":
                                    return (
                                        <Text
                                            key={i}
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    item.primary.text_content
                                                        .html,
                                            }}
                                            animate={animation}
                                            variants={{
                                                before: {
                                                    opacity: 0,
                                                },
                                                after: {
                                                    opacity: 1,
                                                },
                                            }}
                                            transition={transition}
                                        />
                                    )
                                case "PrismicBlogPostBodyVideo":
                                    return (
                                        <VideoWrapper key={i}>
                                            <YoutubeVideo
                                                data={{
                                                    youtube_id:
                                                        item.primary.youtube_id,
                                                }}
                                                type="how-to"
                                            />
                                        </VideoWrapper>
                                    )
                                case "PrismicBlogPostBodyGallery":
                                    return (
                                        <SwiperCarousel
                                            key={i}
                                            lang={langSlug}
                                            data={item}
                                            type="gallery"
                                        />
                                    )
                                case "PrismicBlogPostBodyImage": {
                                    if (!item.primary) return
                                    return (
                                        <StyledLinkedImage
                                            key={i}
                                            lang={langSlug}
                                            product={
                                                item.primary.product_link &&
                                                item.primary.product_link
                                                    .document
                                            }
                                            image={item.primary.image}
                                        />
                                    )
                                }
                            }
                        })()
                    )}
                </Wrapper>
            )}
            {related_items && related_items.length > 0 && (
                <Related
                    lang={langSlug}
                    type="blog"
                    data={related_items}
                    title="Dive into other blogs"
                    subtitle="Read more"
                />
            )}
        </>
    )
}

export const QUERY_BLOGPOST = graphql`
    query BlogPost($id: String!) {
        item: prismicBlogPost(id: { eq: $id }) {
            id
            lang
            uid
            ...BlogPostQuery
        }
    }
`

export default BlogPost
