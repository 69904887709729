import React from "react"
import styled from "styled-components"

import FacebookSVG from "./../graphics/share/share-facebook.svg"
import TwitterSVG from "./../graphics/share/share-twitter.svg"
import LinkedinSVG from "./../graphics/share/share-linkedin.svg"
import WhatsappSVG from "./../graphics/share/share-whatsapp.svg"

const Aside = styled.aside`
    position: absolute;

    top: 0;
    left: 22vw;

    /* transform: translateX(-150%); */

    padding: 3rem 1.75rem;

    border: 1px solid ${props => props.theme.colors.darkText};
    border-radius: 1.75rem;

    ${props => props.theme.below.desktop`
        display: none;
    `}
`

const List = styled.ul``

const Item = styled.li`
    text-align: center;

    &:not(:last-of-type) {
        margin-bottom: 2rem;
    }
`

const Link = styled.a``
const Button = styled.button``

const StyledWhatsappSVG = styled(WhatsappSVG)`
    width: 2.5rem;

    path {
        fill: ${props => props.theme.colors.darkText};

        transition: fill 0.2s ease-out;
    }

    &:hover {
        path {
            fill: #fff;
        }
    }
`
const StyledLinkedinSVG = styled(LinkedinSVG)`
    width: 2.5rem;

    path {
        fill: ${props => props.theme.colors.darkText};

        transition: fill 0.2s ease-out;
    }

    &:hover {
        path {
            fill: #fff;
        }
    }
`
const StyledTwitterSVG = styled(TwitterSVG)`
    width: 2.5rem;

    path {
        fill: ${props => props.theme.colors.darkText};

        transition: fill 0.2s ease-out;
    }

    &:hover {
        path {
            fill: #fff;
        }
    }
`
const StyledFacebookSVG = styled(FacebookSVG)`
    width: 2.5rem;

    path {
        fill: ${props => props.theme.colors.darkText};

        transition: fill 0.2s ease-out;
    }

    &:hover {
        path {
            fill: #fff;
        }
    }
`

const ShareLinks = ({ url, title }) => {
    const facebookShareHandler = url => {
        if (typeof window == "undefined") return

        const shareUrl = `https://www.facebook.com/sharer.php?u=${url}`
        const size = 570
        const left = (window.innerWidth - size) / 2
        const top = (window.innerHeight - size) / 2
        const params = `menubar=no, toolbar=no, status=no, width=${size}, height=${size}, top=${top}, left=${left}`

        window.open(shareUrl, "NewWindow", params)
    }

    return (
        <Aside>
            <List>
                <Item>
                    <Link
                        href={`whatsapp://send?text=${title.text} Read more: ${url}.`}
                        target="_blank"
                    >
                        <StyledWhatsappSVG />
                    </Link>
                </Item>
                <Item>
                    <Link
                        href={` http://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title.text} Read more: ${url}.`}
                        target="_blank"
                    >
                        <StyledLinkedinSVG />
                    </Link>
                </Item>
                <Item>
                    <Link
                        href={`https://twitter.com/intent/tweet/?text=${title.text} Read more: ${url}.`}
                        target="_blank"
                    >
                        <StyledTwitterSVG />
                    </Link>
                </Item>
                <Item>
                    <Button
                        onClick={() => facebookShareHandler(url)}
                        target="_blank"
                    >
                        <StyledFacebookSVG />
                    </Button>
                </Item>
            </List>
        </Aside>
    )
}

export default ShareLinks
