import React from "react"
import styled from "styled-components"

import TitleWithButton from "./../../components/TitleWithButton"
import ButtonCircle from "../Buttons/ButtonCircle"
import TitleComponent from "../Title"
import { generatePath } from "../../utils/helpers"

const Wrapper = styled.section`
    position: relative;
    width: 100%;
    margin: 12rem auto;
    padding: 10rem 0;

    background-color: ${props => props.theme.colors.darkLight};

    ${props => props.theme.below.desktop`
        margin: 0;
        margin-bottom: 3rem;
        padding: 6rem 0;
    `}
`

const Inner = styled.div`
    width: 100%;

    /* padding: 6rem 0; */

    text-align: center;

    ${props => props.theme.above.desktop`
        max-width: 80rem;

        margin: 0 auto;
        // padding: 12rem 0;
    `}
`

const StyledTitleWithButton = styled(TitleWithButton)`
    /* text-align: center;

    .line {
        display: none;
    }

    .description {
        max-width: 55rem;

        margin: 0 auto 2rem auto;
    }

    ${props => props.theme.below.desktop`
        .button-circle {
            display: block;
        }
    `} */
`

const TitleWithButtonWrapper = styled.div`
    text-align: center;

    .title-wrapper {
        margin-bottom: 2.5rem;
        /* max-width: none; */
    }

    .button-circle {
        display: inline-block;
    }

    .description {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            width: 20rem;
            height: 1px;
            background: #fff;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        padding-top: 2rem;
    }

    .paragraph {
        color: #fff;
        font-size: 2rem;
        font-family: ${props => props.theme.fontFamily.circularMedium};
    }

    ${props => props.theme.below.desktop`
        padding: 0 2rem;

        .title-wrapper {
            padding: 0;
            margin-bottom: 1.5rem;
        }

        .title {
            font-size: 3rem;
            line-height: 1.25;
        }

        .title, .paragraph {
            text-align: center;
        }

        .description::after {
            display: none;
        }
    `}

    /* ${props => props.theme.above.desktop`
        margin-bottom: 15rem;
    `} */
`

// const StyledButtonCircle = styled(ButtonCircle)`
/* ${props => props.theme.below.desktop`
        display: none !important;
    `} */
// `

const CTABanner = ({ lang, data }) => {
    return (
        <Wrapper>
            <Inner>
                <TitleWithButtonWrapper>
                    <TitleComponent
                        title={data.primary.cta_banner_title.text}
                        description={data.primary.cta_banner_description}
                        alignment="center"
                        text
                    />
                    <ButtonCircle
                        type="arrow"
                        direction="right"
                        label={data.primary.cta_banner_button_label}
                        animation={"after"}
                        to={generatePath(lang, data.primary.cta_banner_link)}
                    />
                </TitleWithButtonWrapper>
            </Inner>
        </Wrapper>
    )
}

export default CTABanner
